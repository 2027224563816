import { z } from 'zod';

export const PlanAddon = z.object({
  amount: z.number(),
  id: z.string(),
  name: z.string(),
  quantity: z.number(),
  type: z.union([
    z.literal('high-fid-clone'),
    z.literal('instant-clone'),
    z.literal('base-price-for-metered-plan'),
    z.literal('phone-number'),
  ]),
});

export type PlanAddon = z.infer<typeof PlanAddon>;

export const Package = z.object({
  amount: z.number().optional(),
  created_at: z.number(),
  end: z.number(),
  id: z.string(),
  interval: z.string(),
  name: z.string(),
  quantity: z.number().optional(),
  renewal: z.number(),
  start: z.number(),
  status: z.string(),
  subscription_id: z.string(),
  words: z.number().optional(),
  chars: z.number().optional(),
  hasUnlimitedCredits: z.boolean().optional(),
  high_fid_clone: z.number().optional(),
  zero_shot_clone: z.number().optional(),
  addons: z.record(PlanAddon).optional(),
  is_metered_plan: z.boolean().optional(),
  scheduledCancellation: z.boolean().optional(),
});

export type Package = z.infer<typeof Package>;

export const AddOn = z.object({
  amount: z.number(),
  created_at: z.number(),
  end: z.number(),
  id: z.string(),
  interval: z.string(),
  name: z.string(),
  quantity: z.number(),
  renewal: z.number(),
  start: z.number(),
  status: z.string(),
  subscription_id: z.string(),
  addons: z.record(PlanAddon).optional(),
  is_metered_plan: z.boolean().optional(),
  scheduledCancellation: z.boolean().optional(),
});

export type AddOn = z.infer<typeof AddOn>;

export type Plan = Package | AddOn;
