import React, { useState } from 'react';
import { clsx } from 'clsx';
import { capitalizeFirstChar } from '../../utils/stringUtils.ts';
import { PlanAddon } from '../../domain/billing/Plan.ts';
import { useUser } from '../../contexts/user.context.tsx';
import { useAuth } from '../../contexts/auth.context.tsx';
import { cancelPlan } from '../../API/billing.requests.ts';
import { eventsTracker } from '../../infra/analytics/eventsTracker.ts';
import { AxiosError } from 'axios';
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
  useNotifications,
} from '../../__playrepo/ui-components/src/NotificationSnackbar.tsx';

export function BillingPlanCard({
  name,
  interval,
  subscription_id,
  status,
  amount,
  startedOn,
  renewalOn,
  planAddons,
  is_metered_plan = false,
  scheduledCancellation = false,
}: {
  name: string;
  interval: string;
  subscription_id: string;
  status: string;
  amount: string;
  startedOn: string;
  renewalOn: string;
  planAddons: PlanAddon[];
  is_metered_plan: boolean;
  scheduledCancellation: boolean;
}) {
  const { user } = useUser();
  const auth = useAuth();
  const { setNotification } = useNotifications();

  const [isCanceling, setIsCanceling] = useState(false);

  const handleCancelPlan = async (planId: string, reason: string) => {
    setIsCanceling(true);
    try {
      if (!auth.currentUser) {
        throw new Error('User not authorized');
      }
      if (!user) {
        throw new Error('User not found');
      }

      const idToken = await auth.currentUser?.getIdToken();
      await cancelPlan(idToken, reason, planId, user.id, user.name ?? '');

      setNotification({
        status: NOTIFICATION_TYPE_SUCCESS,
        message: 'Plan canceled successfully',
      });
    } catch (e: unknown) {
      const errorMessage =
        (e instanceof AxiosError && e?.response?.data.error) ??
        (e instanceof Error && e.message) ??
        'Something went wrong';
      setNotification({
        status: NOTIFICATION_TYPE_ERROR,
        message: errorMessage,
      });
    }
    setIsCanceling(false);
  };

  const onClickCancelPlan = (planId: string) => {
    eventsTracker.sendProfitwellEvent('init_cancellation_flow', { subscription_id: planId }).then((result) => {
      if (result.status === 'retained' || result.status === 'aborted') {
        return;
      }
      return handleCancelPlan(planId, result.cancelReason ?? '');
    });
  };

  return (
    <div className="flex w-96 flex-col gap-4 rounded-xl p-6 dark:bg-neutral-900/50">
      <div className="mb-4 flex items-start justify-between">
        <div className="text-sm font-medium">{name}</div>
        <div className="flex flex-col items-end">
          <div
            className={clsx('mb-2 rounded px-4 py-0.5 text-sm', {
              'bg-primary/10 text-primary': status === 'active',
              'bg-neutral-800': status === 'canceled',
              'bg-error-900/20 text-error-600': status !== 'active' && status !== 'canceled',
            })}
          >
            {status !== 'past_due' ? capitalizeFirstChar(status) : 'On Hold'}
          </div>
          {status !== 'canceled' && !scheduledCancellation && (
            <button
              disabled={isCanceling}
              onClick={() => onClickCancelPlan(subscription_id)}
              className="cursor-pointer self-end text-xs"
            >
              Cancel
            </button>
          )}
        </div>
      </div>
      {!is_metered_plan ? (
        <div>
          <span className="mr-1.5 text-3xl font-medium">{amount}</span>
          <span className="dark:text-neutral-400">/{interval}</span>
        </div>
      ) : null}
      {planAddons
        .filter((addon) => addon.quantity > 0)
        .map((addon) => (
          <div key={addon.id}>
            <span className="mr-1.5 text-3xl font-medium">${(addon.amount * addon.quantity) / 100}</span>
            <span className="dark:text-neutral-400">
              / {addon.quantity} {addon.name}
            </span>
          </div>
        ))}
      <div className="mt-2 flex flex-col gap-1 text-sm">
        <div>Started on {startedOn}</div>
        {status !== 'past_due' ? (
          <div>Renews on {renewalOn}</div>
        ) : (
          <div className="text-error-600"> Payment failed </div>
        )}
      </div>
      {scheduledCancellation && status !== 'canceled' && (
        <div className="text-error-600">Cancellation scheduled at {renewalOn}</div>
      )}
    </div>
  );
}
